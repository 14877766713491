@import url('https://fonts.googleapis.com/css2?family=Rambla&display=swap');

/* -------------------------------------------------------------------------- */
/*  @ Body
/* -------------------------------------------------------------------------- */

body fuse-splash-screen {
    @apply bg-cyan-900;
}

body,
.light .dark {
    @apply bg-cyan-900 #{'!important'};
}

.fuse-horizontal-header fuse-horizontal-navigation {
    justify-content: space-between !important;
    @apply text-white #{'!important'};
    //background: #143847 !important;

    &.mat-icon{
        @apply text-white #{'!important'};
    }
} 

.fuse-horizontal-header fuse-horizontal-navigation .fuse-horizontal-navigation-wrapper fuse-horizontal-navigation-basic-item .fuse-horizontal-navigation-item-active .fuse-horizontal-navigation-item-title, fuse-horizontal-navigation .fuse-horizontal-navigation-wrapper fuse-horizontal-navigation-basic-item .fuse-horizontal-navigation-item-active-forced .fuse-horizontal-navigation-item-title{
    @apply text-bringpro-green relative #{'!important'};
}

.fuse-horizontal-header fuse-horizontal-navigation .fuse-horizontal-navigation-wrapper fuse-horizontal-navigation-basic-item .fuse-horizontal-navigation-item-active .fuse-horizontal-navigation-item-title::after{
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #CEED3A;
    bottom: -15px;
    left: 50%;
    @apply absolute w-0 h-0 transform 
  }

/* -------------------------------------------------------------------------- */
/*  @ Fuse
/* -------------------------------------------------------------------------- */

// fuse-vertical-navigation-basic-item >.fuse-vertical-navigation-item-wrapper .fuse-vertical-navigation-item:not(.fuse-vertical-navigation-item-disabled).fuse-vertical-navigation-item-active {
//     @apply rounded-r-none rounded-l-[34.5px] shadow-[-3px_4px_2px_rgba(0,0,0,0.13)] bg-gradient-to-l from-[#D86A15] to-[#FFA41C]  #{'!important'};
// }

// fuse-vertical-navigation-basic-item > .fuse-vertical-navigation-item-wrapper {
//     @apply m-0 ml-3 #{'!important'};
// }

// fuse-vertical-navigation-basic-item .fuse-vertical-navigation-item-wrapper .fuse-vertical-navigation-item .fuse-vertical-navigation-item-title-wrapper .fuse-vertical-navigation-item-title {
//     @apply opacity-100 #{'!important'};
// }

// .fuse-horizontal-header {
//     @apply bg-cyan-900 #{'!important'};
// }

/* -------------------------------------------------------------------------- */
/*  @ Buttons
/* -------------------------------------------------------------------------- */
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
    &.mat-primary {
        @apply  font-bold bg-primary text-white #{'!important'};
    }
}

.mat-mdc-outlined-button {

    /* Not disabled */
    &:not([disabled=true]) {
        @apply bg-white text-orange-500 border border-orange-500 font-bold rounded-full #{'!important'};
    }

    /* Disabled */
    &[disabled=true] {
        @apply bg-white text-orange-500 border border-orange-500 font-bold rounded-full #{'!important'};
    }
}

.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    @apply bg-[#FFA41C] border-[#FFA41C] rounded-md #{'!important'};
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    @apply rounded-md #{'!important'};
}

.mdc-checkbox__native-control:disabled:checked~.mdc-checkbox__background {
    @apply bg-[#d9d1d1] #{'!important'};
}

a {
    @apply text-sky-900 hover:underline font-bold;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after  {
    @apply bg-[#00B929] #{'!important'};
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
    border-radius: 50%;    
    @apply border-white fill-white bg-white #{'!important'};
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon{
    border-radius: 50%;    
    @apply border-white fill-white bg-white #{'!important'};
}

.signature-pad-canvas {
    @apply border-none #{'!important'};
}

/* -------------------------------------------------------------------------- */
/* Mat Card */
/* -------------------------------------------------------------------------- */
// .mat-mdc-card {
//     @apply bg-white flex flex-col shadow-md #{'!important'};
// }

// .mat-mdc-card-header {
//     @apply bg-cyan-900 min-h-14  flex justify-between outline-white #{'!important'};
// }

// .mat-mdc-card-header .mat-mdc-card-title {
//     @apply text-2xl text-white #{'!important'};
// }

// .mat-mdc-card-header .mat-mdc-card-subtitle {
//     @apply text-white #{'!important'};
// }

//Mat Button Toggle
.mat-button-toggle-checked {
    @apply bg-bringpro-green #{'!important'};
  }