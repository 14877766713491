/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.bubble.css';
